import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from 'AuthContext';
import { useUser } from 'UserContext';
import { getError } from "api";
import { createQR } from "api/qr";
import InnerPageLayout from "components/InnerPageLayout/InnerPageLayout";
import formStyles from "components/Form/form.module.css";
import Alert from "components/Alert/Alert";


const NewQRPage = () => {
    const { isAuthenticated, token } = useAuth();
    const { wallets, setUser, user } = useUser();

    const [amount, setAmount] = useState("");
    const [error, setError] = useState(null);
    const [inProgress, setInProgress] = useState(false);

    const navigate = useNavigate();

    if (!isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    // Перевод доступен только пользователям с идентификацией
    if (user?.agent.status === 1) {
        return <Navigate to="/wallets" replace />;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setInProgress(true);
        setError(null);
        try {
            const response = await createQR({ token, from: wallets[0].id, amount });
            navigate("/qr/" + response.id);
        } catch (error) {
            console.log("New QR error", error)
            setError(getError(error.response?.data?.error_code));
        }
        setInProgress(false);
    };

    const goBack = () => {
        setUser(null);
        navigate("/wallets");
    }

    return (
        <InnerPageLayout title="Оплата по QR" backFn={goBack}>
            <div className={formStyles.wrapper}>
                <form className={formStyles.form} onSubmit={handleSubmit} disabled={inProgress}>
                    <div className={formStyles.header}>
                        <h1>Новый QR-платеж</h1>
                        <h2>Введите сумму, создайте платеж и покажите QR клиенту</h2>
                    </div>

                    {error !== null && <Alert type="danger"
                        title={error.title}
                        message={error.message} />}

                    <div className={formStyles.group}>
                        <label htmlFor="amount">Сумма платежа</label>
                        <input
                            type="number"
                            id="amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            pattern="^(?!0[0-9])(\d{1,12})(\.\d{1,2})?$"
                            required
                        />
                    </div>

                    <div className={formStyles.footer}>
                        <button type="submit">
                            {!inProgress && "Создать QR"}
                            {inProgress && "Создаём QR..."}
                        </button>
                    </div>
                </form>
            </div>
        </InnerPageLayout>
    );
};

export default NewQRPage;