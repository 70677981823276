import axios from 'axios';
import { debugError } from "./errors";
import { BASE_URL } from "./config";

export const createQR = async ({ token, from, amount, currency_id = 1 }) => {
    console.log("New QR", { from, amount });

    try {
        const headers = { 'Authorization': `Bearer ${token}` };
        const data = {
            wallet_id: from,
            amount: amount * 100,
            currency_id
        };

        const qr = await axios({ method: 'post', url: `${BASE_URL}/api/v1/qr_payment`, headers, data })
        const { id: qrID, status: qrStatus } = qr.data;

        if (!qrID) throw ({ error_code: 0, message: "Не удалось создать QR" });
        if (!qrStatus === 400) throw ({ error_code: 0, message: "Ошибка при создании QR" });

        return qr.data;
    } catch (error) {
        debugError(error);
        throw error;
    }
}

export const getQR = async ({ token, id }) => {
    try {
        const path = `${BASE_URL}/api/v1/qr_payment?id=${id}`;
        const headers = { 'Authorization': `Bearer ${token}` };
        const response = await axios.get(path, { headers });

        return response.data;
    } catch (error) {
        debugError(error);
        throw error;
    }
}