import axios from 'axios';
import { debugError } from "./errors";
import { BASE_URL } from "./config";

export const getTransaction = async ({ token, id }) => {
    try {
        const path = `${BASE_URL}/api/v1/transaction?id=${id}`;
        const headers = { 'Authorization': `Bearer ${token}` };
        const response = await axios.get(path, { headers });
        return response.data;
    } catch (error) {
        debugError(error);
        throw error;
    }
}