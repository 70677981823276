import React, { useState, useEffect, useContext, useCallback } from 'react';
import { getUser, getWallets } from 'api';
import { getInvoices } from 'api/invoices';
import { useAuth } from 'AuthContext';

const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
    const { token } = useAuth();
    const [user, setUser] = useState(null);
    const [fetchingUser, setFetchingUser] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [fetchingWallets, setFetchingWallets] = useState(false);
    const [invoices, setInvoices] = useState(null);
    const [fetchingInvoices, setFetchingInvoices] = useState(false);

    useEffect(() => {
        if (!token) return;
        if (token && (!user || !user.id)) {
            console.info("UserProvider: authorized, fetching user...");
            FetchUser();
        } else {
            console.info("UserProvider: authorized, ", user.id);
        }

        if (token && wallets.length === 0) {
            console.info("UserProvider: fetching wallets...");
            fetchWallets();
        } else {
            console.info("UserProvider:", wallets.length, "wallets");
        }

        if (token && !invoices) {
            console.info("UserProvider: fetching invoices...");
            fetchInvoices();
        } else {
            console.info("UserProvider:", invoices.length, "invoices");
        }        
    }, [token, user, wallets, invoices]);


    const FetchUser = useCallback(async () => {
        setFetchingUser(true);
        const userInfo = await getUser(token);
        userInfo && setUser(userInfo);
        setFetchingUser(false);
    });

    const fetchWallets = async () => {
        setFetchingWallets(true);
        const walletsData = await getWallets(token);
        walletsData && setWallets(walletsData);
        setFetchingWallets(false);
    };

    const fetchInvoices = async () => {
        setFetchingInvoices(true);
        const invoicesData = await getInvoices(token);
        invoicesData && setInvoices(invoicesData);
        setFetchingInvoices(false);
    };


    return (
        <UserContext.Provider value={{
            user, fetchingUser, setUser,
            wallets, fetchingWallets, setWallets,
            invoices, fetchingInvoices, setInvoices
        }}>
            {children}
        </UserContext.Provider>
    );
}

export const useUser = () => useContext(UserContext);
