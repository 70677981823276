import React from 'react';
import { Navigate, Link } from 'react-router-dom';
import { useAuth } from 'AuthContext';
import { useUser } from 'UserContext';
import InnerPageLayout from "components/InnerPageLayout/InnerPageLayout";
import Wallets from "./Wallets/Wallets";
import Invoices from './Invoices/Invoices';
import Alert from "components/Alert/Alert";
import styles from "./homePage.module.css";

const HomePage = () => {
  const { isAuthenticated } = useAuth();
  const { user, wallets, fetchingWallets, invoices, fetchingInvoices } = useUser();

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <InnerPageLayout type="home" showPreloader={(!user || fetchingWallets || fetchingInvoices)}>
      <div className={`${styles.container} full-height`}>
        <div className={styles.page}>
          {wallets.length === 0 && <Alert
            type="danger"
            title="Кошельки не найдены"
            message="Обратитесь за помощью в нашу тех. поддержку" />}
          <Wallets list={wallets} />
          <Invoices list={invoices}/>
        </div>

      </div>
    </InnerPageLayout>
  );
};

export default HomePage;