import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import { useAuth } from 'AuthContext';
import { useSign } from '../SignContext';
import InnerPageLayout from "components/InnerPageLayout/InnerPageLayout";
import formStyles from "components/Form/form.module.css";
import Alert from "components/Alert/Alert";
import { getError, changePassword } from "api";

const RepairPasswordPage = () => {
    const [password, setPassword] = useState("");
    const [inProgress, setInProgress] = useState(false);

    const { isAuthenticated } = useAuth();
    const { step, setStep, regToken } = useSign();
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Step from RegistrationPage", step);
        if (step === 1) navigate('/repair');
        if (step === 4) navigate('/signin');
    }, [step]);

    if (isAuthenticated) {
        return <Navigate to="/wallets" replace />;
    }

    const handleBack = event => {
        event.preventDefault();
        setPassword("");
        setStep(1);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setInProgress(true);
        console.log("password", password);
        try {
            const response = await changePassword({ password, token: regToken });
            setPassword("");
            setStep(4);
        } catch (error) {
            console.log("error", error)
            setError(getError(error.response?.data?.error_code || 0));
        }
        setInProgress(false);
    };

    return (
        <InnerPageLayout title="Восстановить пароль" backFn={handleBack}>
            <div className={formStyles.wrapper}>
                <form className={formStyles.form} onSubmit={handleSubmit} disabled={inProgress}>
                    <div className={formStyles.header}>
                        <h1>Номер подтверждён 👌</h1>
                        <h2>Создайте новый пароль</h2>
                    </div>

                    {error !== null && <Alert type="danger"
                        title={error.title}
                        message={error.message} />}

                    <div className={formStyles.group}>
                        <label htmlFor="name">Новый пароль</label>
                        <input
                            type="text"
                            id="password"
                            placeholder=""
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <div className={formStyles.footer}>
                        <button type="submit">Изменить пароль</button>
                    </div>
                </form>
            </div>
        </InnerPageLayout>
    );
};

export default RepairPasswordPage;