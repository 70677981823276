import { formatAmount, formatPhone, formatDate } from "utils";
import { Link } from "react-router-dom";

const statuses = {
    0: "Отправлен",
    1: "Отклонён ❌",
    2: "Подтверждён ☑️"
};

const getTransactionStatus = transaction => {
    const transactionStatuses = {
        0: "В обработке",
        200: "В обработке",
        10: "В обработке",
        100: "В обработке",
        50: "В обработке",
        600: "Оплачен ✅",
        400: "Ошибка оплаты ⛔️"
    };

    if (transaction) return transactionStatuses[transaction.status]
    return "Ожидает оплату ⌛️";
}

const Invoice = ({ phone, amount, status, created, transaction }) => {
    const date = formatDate(created);
    const transactionStatus = getTransactionStatus(transaction);
    return (
        <tr onClick={()=>alert(phone)}>
            <td data-label="Клиент">{formatPhone(phone)}</td>
            <td data-label="Сумма">{formatAmount(amount)}</td>
            <td data-label="Дата">{date.day} в {date.time}</td>
            <td data-label="Статус">{statuses[status]}</td>
            <td data-label="Оплата">{transactionStatus}</td>
        </tr>
    );
};

export default Invoice;


