import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from 'AuthContext';
import { useUser } from 'UserContext';
import { getError } from "api";
import { createInvoice } from "api/invoices";
import InnerPageLayout from "components/InnerPageLayout/InnerPageLayout";
import formStyles from "components/Form/form.module.css";
import Alert from "components/Alert/Alert";
import { formatPhone } from "utils";

const NewInvoicePage = () => {
    const { isAuthenticated, token } = useAuth();
    const { wallets, setUser, user } = useUser();

    const [payee, setPayee] = useState("");
    const [amount, setAmount] = useState("");
    const [text, setText] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [payorAgent, setPayorAgent] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    const navigate = useNavigate();

    if (!isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    // Перевод доступен только пользователям с идентификацией
    if (user?.agent.status === 1) {
        return <Navigate to="/wallets" replace />;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setInProgress(true);
        setSuccess(false);
        setError(null);
        try {
            const response = await createInvoice({ token, text, from: wallets[0].id, to: payee, amount });
            setSuccess(true);
            setPayorAgent(response.payer_agent.phone);
            setPayee("");
            setAmount("");
            setText("");
        } catch (error) {
            console.log("New invoice error", error)
            setError(getError(error.response?.data?.error_code));
        }
        setInProgress(false);
    };

    const goBack = () => {
        setUser(null);
        navigate("/wallets");
    }

    return (
        <InnerPageLayout title="Счёт на оплату" backFn={goBack}>
            <div className={formStyles.wrapper}>
                <form className={formStyles.form} onSubmit={handleSubmit} disabled={inProgress}>
                    <div className={formStyles.header}>
                        <h1>Создайте новый счёт</h1>
                        <h2>Введите номер заказа, телефон клиента и сумму</h2>
                    </div>

                    {error !== null && <Alert type="danger"
                        title={error.title}
                        message={error.message} />}

                    {success !== false && <Alert type="success"
                        title="Счёт успешно создан"
                        message={`Отправлен на номер ${formatPhone(payorAgent)}`} />}

                    <div className={formStyles.group}>
                        <label htmlFor="payee">Номер клиента</label>
                        <input type="tel"
                            id="payee"
                            placeholder="770112345678"
                            value={payee}
                            onChange={(e) => setPayee(e.target.value)}
                            pattern="^7(70[0-9]|771|775|776|777|778)\d{7}$"
                            required
                        />
                    </div>

                    <div className={formStyles.group}>
                        <label htmlFor="amount">Сумма</label>
                        <input
                            type="number"
                            id="amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            pattern="^(?!0[0-9])(\d{1,12})(\.\d{1,2})?$"
                            required
                        />
                    </div>

                    <div className={formStyles.group}>
                        <label htmlFor="comment">Описание заказа</label>
                        <input
                            type="text"
                            id="comment"
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        />
                    </div>

                    <div className={formStyles.footer}>
                        <button type="submit">
                            {!inProgress && "Создать счёт"}
                            {inProgress && "Создаём счёт..."}
                        </button>
                    </div>
                </form>
            </div>
        </InnerPageLayout>
    );
};

export default NewInvoicePage;