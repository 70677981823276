import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'AuthContext';
import { useUser } from 'UserContext';
import { getError } from "api";
import { getQR } from "api/qr";
import { getInvoice } from "api/invoices";
import { getTransaction } from "api/transactions";
import InnerPageLayout from "components/InnerPageLayout/InnerPageLayout";
import formStyles from "components/Form/form.module.css";
import buttonStyles from "components/Button/button.module.css";
import Alert from "components/Alert/Alert";
import styles from "./qr.module.css";
import { QRCodeSVG } from "qrcode.react";

// Constants
const UI_TIMEOUT = 60;
const REQUEST_TIMEOUT = 1000;


const QRPage = () => {
    const { isAuthenticated, token } = useAuth();
    const { wallets, setUser, user } = useUser();

    const [qr, setQR] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [inProgress, setInProgress] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();

    const timeoutID = useRef(null);
    const intervalsCounter = useRef(0);
    const qrRef = useRef(null);
    const invoiceRef = useRef(null);
    const transactionRef = useRef(null);

    // 1. Ждем пока QR не отсканирует покупатель или отваливаемся по таймауту
    useEffect(() => {
        qrRef.current = qr;
    }, [qr]);

    useEffect(() => {
        if (invoiceRef.current !== null) {
            console.log('D:', 'invoice', invoiceRef.current, 'no QR effect');
            return;
        }

        const fetchQR = async (id) => {
            console.log('D:', 'QR = null', qrRef === null);

            setInProgress(true);
            setError(null);

            intervalsCounter.current = intervalsCounter.current + 1;
            if (intervalsCounter.current > UI_TIMEOUT) {
                setError({ title: "Ошибка", message: "Время оплаты по QR истекло 🖕" });
                setQR(null);
                return;
            }

            try {
                console.log('D:', 'getting QR');
                const response = await getQR({ token, id });

                if (qrRef.current === null) {
                    setQR(response);
                    console.log('D:', 'setting QR');
                    timeoutID.current = setTimeout(() => fetchQR(id), REQUEST_TIMEOUT);
                } else {
                    if (response.invoice_id === null) {
                        console.log('D:', 'timeout QR');
                        timeoutID.current = setTimeout(() => fetchQR(id), REQUEST_TIMEOUT);
                    } else {
                        invoiceRef.current = response.invoice_id;
                        console.log('D:', 'clearing timeout because invoice_id');
                        intervalsCounter.current = 0;
                        clearTimeout(timeoutID.current);
                    }
                }
            } catch (error) {
                console.log("QR error", error)
                setError(getError(error.response?.data?.error_code));
                setQR(null);
            }

            setInProgress(false);
        };

        fetchQR(id);

        return () => {
            if (timeoutID.current) {
                console.log('D:', 'clearing timeout');
                clearTimeout(timeoutID.current);
            }
        };
    }, []);

    // 2. Проверяем статус инвойса или отваливаемся по таймауту
    useEffect(() => {
        if (invoiceRef.current === null) {
            console.log('D:', 'no invoice yet');
            return;
        }

        if (transactionRef.current !== null) {
            console.log('D:', 'transaction', transactionRef.current, 'no invoice effect');
            return;
        }

        const fetchInvoice = async id => {
            console.log('D:', 'Invoice id', id);

            intervalsCounter.current = intervalsCounter.current + 1;
            if (intervalsCounter.current > UI_TIMEOUT) {
                setError({ title: "Ошибка", message: "Клиент не подтвердил платеж 🖕" });
                setQR(null);
                return;
            }

            setInProgress(true);
            setError(null);

            try {
                console.log('D:', 'getting invoice..');
                const invoice = await getInvoice({ token, id });
                console.log('D:', 'Invoice', invoice);
                console.log('D:', 'Invoice status', invoice.status);
                if (invoice.status !== 0) {
                    if (invoice.status === 1) {
                        console.log('D:', 'Invoice status - canceled');
                        setQR(null);
                        setError({ title: "Ошибка", message: "Клиент отклонил оплату по QR" });
                    }
                    if (invoice.status === 2) {
                        console.log('D:', 'Invoice status - approved');
                        transactionRef.current = invoice.transaction?.id;
                        intervalsCounter.current = 0;
                    }
                    clearTimeout(timeoutID.current);
                } else {
                    console.log('D:', 'Invoice status - waiting');
                    console.log('D:', 'Timeout invoice');
                    timeoutID.current = setTimeout(() => fetchInvoice(id), REQUEST_TIMEOUT);
                }

            } catch (error) {
                console.log("Invoice error", error)
                setError(getError(error.response?.data?.error_code));
                setQR(null);
            }
            setInProgress(false);
        };

        fetchInvoice(invoiceRef.current);
    }, [invoiceRef.current]);

    // 3. Проверяем статус транзакции или отваливаемся по таймауту
    useEffect(() => {
        if (transactionRef.current === null) {
            console.log('D:', 'no transaction yet');
            return;
        }

        const fetchTransaction = async id => {
            console.log('D:', 'Transaction id', id);

            intervalsCounter.current = intervalsCounter.current + 1;
            if (intervalsCounter.current > UI_TIMEOUT) {
                setError({ title: "Ошибка", message: "Статус платежа неизвестен. Обратитесь в нашу поддержку" });
                setQR(null);
                return;
            }

            try {
                console.log('D:', 'getting transaction..');
                const transaction = await getTransaction({ token, id });
                console.log('D:', 'Transaction', transaction);
                console.log('D:', 'Transaction status', transaction.status);

                if (transaction.status === 400 || transaction.status === 600) {
                    if (transaction.status === 400) {
                        console.log('D:', 'Transaction status - fail');
                        setError(transaction.error);
                    }
                    if (transaction.status === 600) {
                        console.log('D:', 'Transaction status - success');
                        setSuccess(true);
                        intervalsCounter.current = 0;
                    }
                    setQR(null);
                    clearTimeout(timeoutID.current);
                } else {
                    console.log('D:', 'Transaction status - in progress');
                    console.log('D:', 'Transaction invoice');
                    timeoutID.current = setTimeout(() => fetchTransaction(id), REQUEST_TIMEOUT);
                }

            } catch (error) {
                console.log("Transaction error", error)
                setError(getError(error.response?.data?.error_code));
                setQR(null);
            }

            setInProgress(true);
        }

        fetchTransaction(transactionRef.current);
    }, [transactionRef.current]);

    if (!isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    // Перевод доступен только пользователям с идентификацией
    if (user?.agent.status === 1) {
        return <Navigate to="/wallets" replace />;
    }

    const goBack = () => {
        setUser(null);
        navigate("/wallets");
    }

    const isInvoice = invoiceRef.current !== null && error === null && success === null;
    const isTransaction = transactionRef.current !== null && error === null && success === null;
    const isWaiting = !isInvoice && !isTransaction && !error && !success;
    const timeout = UI_TIMEOUT - intervalsCounter.current;

    return (
        <InnerPageLayout title="Оплата по QR" backFn={goBack}>
            <div className={formStyles.wrapper}>
                <form className={formStyles.form}>
                    {error !== null && <Alert type="danger"
                        title={error.title}
                        message={error.message} />}
                    {success && <Alert type="success" message={`Клиент оплатил счёт 👍`} />}

                    {qr && <QRCodeSVG className={styles.QR} fgColor="var(--brand-dark)" size="256" value={JSON.stringify(qr)} />}
                    <div className={styles.status}>
                        {isWaiting && <p>👀 Покажите QR покупателю{transactionRef.current ? '' : '... (' + timeout + ')'} </p>}
                        {isInvoice && <p>🤳 Клиент отсканировал QR{transactionRef.current ? '' : '... (' + timeout + ')'} </p>}
                        {isTransaction && <p>💸 Проводим платеж... {'(' + timeout + ')'}</p>}
                        {(error || success) && <Link to="/wallets" className={buttonStyles.button}>👈 Вернуться на главную</Link>}
                    </div>
                </form>
            </div>
        </InnerPageLayout>
    );
};

export default QRPage;